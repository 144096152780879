import { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { BFolder } from "../api/types";
import fileArchiveSvg from "../assets/img/file-archive.svg";
import fileCssSvg from "../assets/img/file-css.svg";
import fileHtmlSvg from "../assets/img/file-html.svg";
import fileDocSvg from "../assets/img/file-doc.svg";
import fileDocxSvg from "../assets/img/file-docx.svg";
import fileJsSvg from "../assets/img/file-js.svg";
import fileJsonSvg from "../assets/img/file-json.svg";
import filePdfSvg from "../assets/img/file-pdf.svg";
import fileSvgSvg from "../assets/img/file-svg.svg";
import fileJpgSvg from "../assets/img/file-jpg.svg";
import filePngSvg from "../assets/img/file-png.svg";
import fileWebpSvg from "../assets/img/file-webp.svg";
import fileDefaultSvg from "../assets/img/file-default.svg";

export const stringToBoolean = (value: string): boolean => {
  if (value === "true") return true;

  if (value === "false") return false;

  return false;
};

export const findPathById = (arr: BFolder[], targetId: number) => {
  const findPathRecursive = (currentId: number, path: number[]): number[] | null => {
    for (let i = 0; i < arr.length; i += 1) {
      const item = arr[i];
      if (item.id === currentId) {
        path.unshift(item.id);
        if (item.parentId === null) {
          return path;
        }
        return findPathRecursive(item.parentId, path);
      }
    }
    return null;
  };

  return findPathRecursive(targetId, []) || null;
};

export const capitalize = (str: string) => {
  if (str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatSizeUnits = (bytes: number) => {
  let response: string = "";
  if (bytes >= 1073741824) {
    response = `${(bytes / 1073741824).toFixed(2)} GB`;
  } else if (bytes >= 1048576) {
    response = `${(bytes / 1048576).toFixed(2)} MB`;
  } else if (bytes >= 1024) {
    response = `${(bytes / 1024).toFixed(2)} KB`;
  } else if (bytes > 1) {
    response = `${bytes} B`;
  } else if (bytes === 1) {
    response = "1 B";
  } else {
    response = "0 B";
  }
  return response;
};

type DateFormat = "DD-MM-YYYY" | "DD.MM.YYYY";

export const formatDate = (date: string, format: DateFormat = "DD.MM.YYYY") => {
  return moment(new Date(date)).format(format);
};

export const onClickOutside = (ref: RefObject<HTMLElement> | RefObject<HTMLElement>[], handler: (e: Event) => void) => {
  useEffect(() => {
    const listener = (e: Event) => {
      if (Array.isArray(ref)) {
        let contains: boolean | undefined = false;

        for (let i = 0; i < ref.length; i += 1) {
          contains = !ref[i].current || ref[i].current?.contains(e.target as Node);
        }

        if (contains) return;

        handler(e);
      } else {
        if (!ref.current || ref.current.contains(e.target as Node)) {
          return;
        }

        handler(e);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

const LS = window.localStorage;

export const usePersistentState = <S>(initialState: S | (() => S), key: string): [S, Dispatch<SetStateAction<S>>] => {
  const LSValue = LS.getItem(key);
  const [value, setValue] = useState<S>(LSValue ? (JSON.parse(LSValue) as S) : initialState);

  useEffect(() => {
    LS.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
};

export const images: Record<string, string | ((file: File) => string)> = {
  "7z": fileArchiveSvg,
  zip: fileArchiveSvg,
  css: fileCssSvg,
  html: fileHtmlSvg,
  js: fileJsSvg,
  json: fileJsonSvg,
  pdf: filePdfSvg,
  doc: fileDocSvg,
  docx: fileDocxSvg,
  default: fileDefaultSvg,
  jpeg: (file?: File) => (file ? URL.createObjectURL(file) : fileJpgSvg),
  jpg: (file?: File) => (file ? URL.createObjectURL(file) : fileJpgSvg),
  webp: (file?: File) => (file ? URL.createObjectURL(file) : fileWebpSvg),
  png: (file?: File) => (file ? URL.createObjectURL(file) : filePngSvg),
  svg: (file?: File) => (file ? URL.createObjectURL(file) : fileSvgSvg),
};

export const getPreview = (extension: string, file?: File): string => {
  let preview: string = images.default as string;

  if (Object.keys(images).includes(extension)) {
    // @ts-ignore
    preview = typeof images[extension] === "function" ? images[extension](file) : images[extension];
  }

  return preview;
};

export const isImageFile = (extension: string): boolean =>
  extension === "png" || extension === "jpeg" || extension === "jpg" || extension === "webp" || extension === "svg";

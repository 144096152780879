import { FC, useEffect } from "react";
import cn from "classnames";
import Api from "../../api";
import Store from "../../store";
import Aside from "../../components/Aside";
import Content from "../../components/Content";
import HeaderSearch from "../../components/Header/Search";
import HeaderDefault from "../../components/Header/Default";
import MoreInformation from "../../components/MoreInformation";
import CollectionOfModals from "../../components/Modal/Collection";

const Main: FC = () => {
  const store = Store.useContainer();

  const getData = async () => {
    await store.common.setIsLoading(true);

    const files = await Api.files.getAll();
    const folders = await Api.folders.getAll();

    store.files.setFiles(files);
    store.folders.setFolders(folders);

    await store.common.setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ height: "100vh", display: "flex", overflow: "hidden" }}>
      <Aside />
      <div className={cn("b-main")}>
        {store.common.isSearch ? <HeaderSearch /> : <HeaderDefault />}
        <Content />
      </div>
      <MoreInformation />
      <CollectionOfModals />
    </div>
  );
};

export default Main;

import { FC, ReactElement } from "react";
import cn from "classnames";

type Props = {
  size?: "xs" | "sm" | "md" | "lg";
  title: string;
  onCancel: () => void;
  hideCancel?: boolean;
  onSuccess: () => void;
  children: ReactElement;
};

/**
 * **size:** xs - 360; sm - 560; md - 760; lg - 960;
 * */
const Modal: FC<Props> = ({ size, title, onCancel, hideCancel, onSuccess, children }) => {
  return (
    <div className={cn("b-modal", `b-modal_${size}`)}>
      <div className={cn("b-modal__back")} onClick={onCancel} />
      <div className={cn("b-modal__inner")}>
        <div className={cn("b-modal__header")}>
          <div className={cn("b-modal__title")}>{title}</div>
          <div className={cn("b-modal__close")} onClick={onCancel}>
            <span className={cn("becon becon--menu--close-big")} />
          </div>
        </div>
        <div className={cn("b-modal__main")}>{children}</div>
        <div className={cn("b-modal__footer")}>
          {!hideCancel ? (
            <div className={cn("b-button b-button_sm b-button_white b-modal__button")} onClick={onCancel}>
              <div className={cn("b-button__value")}>Cancel</div>
            </div>
          ) : null}
          <div className={cn("b-button b-button_sm b-button_primary b-modal__button")} onClick={onSuccess}>
            <div className={cn("b-button__value")}>Done</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  size: "sm",
  hideCancel: false,
};

export default Modal;

import { useState } from "react";
import Api from "../../api";

const store = () => {
  const [used, setUsed] = useState<number>(0);
  const [available, setAvailable] = useState<number>(0);

  const getData = async (): Promise<void> => {
    const data = await Api.storage.getUsage();

    if (data) {
      setUsed(data.used);
      setAvailable(data.available);
    }
  };

  return {
    used,
    available,
    getData,
  };
};

export default store;

import { FC, useState } from "react";
import cn from "classnames";
import Modal from "../index";
import { BObjectType } from "../../../helpers/types";
import Api from "../../../api";
import { BRelation } from "../../../api/types";

type Props = {
  type: BObjectType;
  id: number;
  onCancel: () => void;
  onSuccess: () => void;
};

const RelationModal: FC<Props> = ({ type, id, onCancel, onSuccess }) => {
  const [relations, setRelations] = useState<BRelation[]>([]);

  const functions: Record<BObjectType, () => void | Promise<void>> = {
    file: async (): Promise<void> => {
      const data = await Api.relations.getFileRelations(id);

      if (data) setRelations(data);
    },
    folder: async (): Promise<void> => {
      const data = await Api.relations.getFolderRelations(id);

      if (data) setRelations(data);
    },
  };

  const onCancelWrapper = (): void => {
    onCancel();
  };

  const onSuccessWrapper = async (): Promise<void> => {
    functions[type]();

    onSuccess();
  };
  return (
    <Modal size="xs" title="Relations" onCancel={onCancelWrapper} onSuccess={onSuccessWrapper} hideCancel>
      {relations.length ? (
        <table>
          <tbody>
            {relations.map((item) => (
              <tr>
                <td>{JSON.stringify(item)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={cn("m-fs-title-1 m-c-dark m-fw-medium")}>Relations not found</div>
      )}
    </Modal>
  );
};

export default RelationModal;

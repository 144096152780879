export enum BObjectTypeEnum {
  file = "file",
  folder = "folder",
}

export type BObjectType = BObjectTypeEnum;

export type BDragAndDropPayload = {
  type: BObjectType;
  id: number;
};

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../api";
import Store from "../../store";

type Props = {};

const Auth: FC<Props> = () => {
  const { user, common } = Store.useContainer();
  const [email, setEmail] = useState<string>("dima@beeonis.com");
  const [password, setPassword] = useState<string>("password");
  const navigate = useNavigate();

  const login = async () => {
    common.setIsLoading(true);

    const token: string = (await Api.user.login(email, password)) || "";

    if (!token) return;

    await user.setIsAuth(true);
    await user.setToken(token);

    common.setIsLoading(false);

    navigate("/");
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          gap: 16,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <div style={{ marginBottom: 8 }}>Email</div>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <div style={{ marginBottom: 8 }}>Password</div>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="button" onClick={login}>
          login
        </button>
      </div>
    </div>
  );
};

export default Auth;

import { FC } from "react";
import cn from "classnames";
import Store from "../../../store";
import Item from "./Item";

type Props = {};

const List: FC<Props> = () => {
  const store = Store.useContainer();

  return (
    <div className={cn("b-aside__tree")}>
      <div className={cn("b-aside-tree")}>
        <div className={cn("b-aside-tree__list")}>
          {/* eslint-disable-next-line react/no-children-prop */}
          <Item name="Storage" id={null} children={store.folders.foldersTree} defaultOpen />
        </div>
      </div>
    </div>
  );
};

export default List;

import { useState, useEffect } from "react";
import { BFolder, BFolderObject } from "../../api/types";
import { findPathById } from "../../helpers";
import Api from "../../api";

const store = () => {
  const [folders, setFolders] = useState<BFolder[]>([]);
  const [foldersTree, setFoldersTree] = useState<BFolder[]>([]);
  const [idMapping, setIdMapping] = useState<Record<number, number>>({});
  const [selectedFolder, setSelectedFolder] = useState<BFolder | null>(null);
  const [pathToCurrentFolder, setPathToCurrentFolder] = useState<BFolder[]>([]);
  const [selectedFolderObjects, setSelectedFolderObjects] = useState<BFolderObject>({ files: [], folders: [] });

  const getSelectedFolderObjects = async () => {
    const defaultData: BFolderObject = { files: [], folders: [] };

    const data = await Api.folders.getFolderObjects(selectedFolder ? selectedFolder.id : null);

    setSelectedFolderObjects(data || defaultData);
  };

  const moveFolder = async (movedFolderId: number, destinationFolderId: number | null) => {
    await Api.folders.move(movedFolderId, destinationFolderId);

    await getSelectedFolderObjects();

    setFolders(await Api.folders.getAll());
  };

  useEffect(() => {
    const root: BFolder[] = [];
    const localIdMapping = folders.reduce((acc: Record<number, number>, folder: BFolder, index: number) => {
      acc[folder.id] = index;

      return acc;
    }, {});

    folders.forEach((folder) => {
      if (folder.parentId === null) {
        root.push(folder);

        return;
      }

      const parentFolder = folders[localIdMapping[folder.parentId]];
      parentFolder.children = [...(parentFolder.children || []), folder];
    });

    setFoldersTree(root);
    setIdMapping(localIdMapping);
  }, [folders]);

  useEffect(() => {
    if (selectedFolder) {
      const path = findPathById(folders, selectedFolder.id);

      if (path) {
        setPathToCurrentFolder(path.map((id) => folders[idMapping[id]]));
      }
    } else {
      setPathToCurrentFolder([]);
    }
  }, [selectedFolder]);

  return {
    folders,
    setFolders,
    foldersTree,
    idMapping,
    selectedFolder,
    setSelectedFolder,
    pathToCurrentFolder,
    setPathToCurrentFolder,
    selectedFolderObjects,
    getSelectedFolderObjects,
    moveFolder,
  };
};

export default store;

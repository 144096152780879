import { FC } from "react";
import cn from "classnames";
import { formatSizeUnits } from "../../../../../helpers";
import { FolderListItemProps } from "../types";

const Icon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M14.0704 4.26239H11.6178C11.0068 4.26643 10.4267 3.99465 10.0396 3.52294L9.23192 2.40637C8.85122 1.93053 8.27116 1.65747 7.66109 1.66692H5.92725C2.81524 1.66692 1.66676 3.49336 1.66676 6.59905V9.95615C1.66288 10.3253 18.3298 10.3249 18.3309 9.95615V8.98008C18.3457 5.87439 17.2268 4.26239 14.0704 4.26239Z"
      fill="#F2A60D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3602 5.45286C17.6268 5.7646 17.8328 6.12319 17.9678 6.51028C18.2333 7.30585 18.3562 8.14189 18.3308 8.98004V13.3576C18.3298 13.7263 18.3025 14.0945 18.2493 14.4594C18.148 15.1033 17.9215 15.7212 17.5825 16.2784C17.4267 16.5475 17.2375 16.796 17.0194 17.0178C16.032 17.924 14.7209 18.3957 13.3813 18.3267H6.60893C5.26716 18.3952 3.95393 17.9237 2.96342 17.0178C2.74792 16.7955 2.56122 16.5471 2.40771 16.2784C2.07071 15.7216 1.84899 15.1031 1.75566 14.4594C1.69632 14.0951 1.66659 13.7266 1.66675 13.3576V8.98004C1.6666 8.61444 1.68639 8.2491 1.72603 7.88565C1.73436 7.82189 1.74686 7.75916 1.75923 7.69708C1.77986 7.59359 1.80012 7.49191 1.80012 7.39022C1.87534 6.95162 2.01255 6.52589 2.20765 6.12576C2.78559 4.89088 3.97112 4.26235 5.91243 4.26235H14.0629C15.1503 4.17826 16.2295 4.50559 17.086 5.17927C17.1847 5.26292 17.2764 5.35442 17.3602 5.45286ZM5.80869 12.9509H14.1963H14.2111C14.3952 12.9589 14.5748 12.893 14.7098 12.768C14.8448 12.6429 14.9241 12.4691 14.9299 12.2854C14.9403 12.1239 14.8871 11.9647 14.7817 11.8417C14.6604 11.6765 14.4682 11.5778 14.263 11.5755H5.80869C5.42812 11.5755 5.1196 11.8834 5.1196 12.2632C5.1196 12.643 5.42812 12.9509 5.80869 12.9509Z"
      fill="#F2A60D"
    />
  </svg>
);

const ListItem: FC<FolderListItemProps> = ({
  folder,
  onDoubleClick,
  onDragStart,
  onClick,
  onDrop,
  onDragOver,
  isHover,
  onDragLeave,
  onContextMenu,
}) => {
  return (
    <div
      className={cn("b-ff-list-item folder", isHover ? "m-bgc-light-400" : "m-bgc-light-200")}
      draggable="true"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onDragStart={onDragStart}
      onDrop={(e) => onDrop(e, folder.id)}
      onDragOver={onDragOver}
      onContextMenu={onContextMenu}
      onDragLeave={onDragLeave}
    >
      <div className={cn("b-ff-list-item__inner")}>
        <div className={cn("b-ff-list-item__drag-place m-c-light-800 m-fs-text-2")}>
          <span className="becon becon--custom--more-vertical" />
        </div>
        <div className={cn("b-ff-list-item__icon m-c-yellow m-fs-text-3")}>{Icon}</div>
        <div className={cn("b-ff-list-item__title m-c-dark m-fs-text-2")}>{folder.name}</div>
        <div className={cn("b-ff-list-item__meta")}>
          <div className={cn("b-ff-list-item__meta-item m-c-dark-100 m-fs-text-2")}>Folder</div>
          <div className={cn("b-ff-list-item__meta-item m-c-dark-100 m-fs-text-2")}>{formatSizeUnits(folder.size)}</div>
          <div className={cn("b-ff-list-item__meta-item m-c-dark-100 m-fs-text-2")}>{folder.fileCount} эл.</div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;

import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Store from "./store";
import Error from "./pages/Error";
import Auth from "./pages/Auth";
import Main from "./pages/Main";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import notification from "./utils/Notification";
import { BNotificationTypeEnum } from "./utils/Notification/types";

function App() {
  const store = Store.useContainer();
  const navigate = useNavigate();

  axios.interceptors.request.use((config) => {
    if (store.user.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${store.user.token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (data) => data,
    (error) => {
      const { status } = error.response;

      if (status === 401) {
        store.user.setToken("");
        store.user.setIsAuth(false);

        navigate("/auth");
      }

      notification(BNotificationTypeEnum.error, error?.response?.data?.message);
      return error;
    },
  );

  useEffect(() => {
    store.files.setSelectedFile(null);
  }, [store.folders.selectedFolder]);

  return (
    <>
      <Routes>
        {store.user.isAuth ? <Route path="/*" element={<Main />} /> : <Route path="/login" element={<Auth />} />}
        <Route path="*" element={store.user.isAuth ? <Error /> : <Auth />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;

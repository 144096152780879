import { FC, useEffect } from "react";
import cn from "classnames";
import Store from "../../store";
import Item from "./Item";
import { BObjectTypeEnum } from "../../helpers/types";
import Loader from "../Loader";
import preload from "./preload";

type Props = {};

const Content: FC<Props> = () => {
  const store = Store.useContainer();

  const getData = async () => {
    await store.common.setIsLoading(true);

    await store.folders.getSelectedFolderObjects();

    await store.common.setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [store.folders.selectedFolder]);

  if (store.common.isLoading)
    return (
      <>
        <div className={cn("b-content")}>
          <div className={cn("b-content__list", store.common.displayingType)}>
            {preload.folders.map((folder) => (
              <div className={cn("b-content__item", "folder")} key={`${folder.id}-${folder.name}`}>
                <Item type={BObjectTypeEnum.folder} data={folder} />
              </div>
            ))}
            {preload.files.map((file) => (
              <div className={cn("b-content__item", "file")} key={`${file.id}-${file.name}`}>
                <Item type={BObjectTypeEnum.file} data={file} />
              </div>
            ))}
          </div>
        </div>
        <Loader />
      </>
    );

  return (
    <div className={cn("b-content")}>
      {store.common.isSearch ? (
        <div className={cn("b-content__list", store.common.displayingType)}>
          {store.common.searchedFiles.length ? (
            store.common.searchedFiles.map((file) => (
              <div className={cn("b-content__item", "file")} key={`${file.id}-${file.name}`}>
                <Item type={BObjectTypeEnum.file} data={file} />
              </div>
            ))
          ) : (
            <div className={cn("m-title-2 m-fw-medium m-c-dark")}>
              No files were found containing the string "{store.common.searchQuery}".
            </div>
          )}
        </div>
      ) : (
        <div className={cn("b-content__list", store.common.displayingType)}>
          {store.folders.selectedFolderObjects.folders.map((folder) => (
            <div className={cn("b-content__item", "folder")} key={`${folder.id}-${folder.name}`}>
              <Item type={BObjectTypeEnum.folder} data={folder} />
            </div>
          ))}
          {store.folders.selectedFolderObjects.files.map((file) => (
            <div className={cn("b-content__item", "file")} key={`${file.id}-${file.name}`}>
              <Item type={BObjectTypeEnum.file} data={file} />
            </div>
          ))}
          {!store.folders.selectedFolderObjects.folders.length && !store.folders.selectedFolderObjects.files.length ? (
            <div className={cn("m-title-2 m-fw-medium m-c-dark")}>This folder is empty</div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Content;

import { FC } from "react";
import cn from "classnames";
import { formatDate, formatSizeUnits, isImageFile } from "../../../../../helpers";
import { FileListItemProps } from "../types";

const ListItem: FC<FileListItemProps> = ({ file, onClick, onDoubleClick, onDragStart, selected, onContextMenu }) => {
  const getIcon: string = isImageFile(file.extension) ? "becon--file--file-image" : "becon--file--file-blank_fill";

  return (
    <div
      className={cn("b-ff-list-item file", selected ? "m-bgc-primary" : "m-bgc-light")}
      draggable="true"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
      onDragStart={onDragStart}
    >
      <div className={cn("b-ff-list-item__inner")}>
        <div className={cn("b-ff-list-item__drag-place m-fs-text-2", selected ? "m-c-light" : "m-c-light-800")}>
          <span className="becon becon--custom--more-vertical" />
        </div>
        <div className={cn("b-ff-list-item__icon", selected ? "m-c-light" : "m-c-primary")}>
          <span className={cn("becon", getIcon)} />
        </div>
        <div className={cn("b-ff-list-item__title m-fs-text-2", selected ? "m-c-light" : "m-c-dark")}>{file.name}</div>
        <div className={cn("b-ff-list-item__meta")}>
          <div className={cn("b-ff-list-item__meta-item m-fs-text-2", selected ? "m-c-light" : "m-c-dark-100")}>
            {file.extension}
          </div>
          <div className={cn("b-ff-list-item__meta-item m-fs-text-2", selected ? "m-c-light" : "m-c-dark-100")}>
            {formatSizeUnits(file.size)}
          </div>
          <div className={cn("b-ff-list-item__meta-item m-fs-text-2", selected ? "m-c-light" : "m-c-dark-100")}>
            {formatDate(file.updatedAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;

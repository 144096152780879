import { FC } from "react";
import cn from "classnames";
import List from "./List";
import Search from "./Search";
import Information from "./Information";

type Props = {};

const Aside: FC<Props> = () => {
  return (
    <aside className={cn("b-aside")}>
      <Search />
      <List />
      <Information />
    </aside>
  );
};

export default Aside;

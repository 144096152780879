import { FC, useState, useEffect } from "react";
import cn from "classnames";
import Store from "../../../store";

type Props = {};

const Search: FC<Props> = () => {
  const store = Store.useContainer();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(store.common.searchQuery);

  const doSearch = async () => {
    await store.common.setIsLoading(true);

    await store.common.setSearchQuery("");
    await store.common.setSearchQuery(value);

    await store.common.setIsLoading(false);
  };

  const clear = async (): Promise<void> => {
    await store.common.setIsLoading(true);

    setValue("");
    await store.common.setSearchQuery("");

    await store.common.setIsLoading(false);
  };

  useEffect(() => {
    setValue(store.common.searchQuery);
  }, [store.common.searchQuery]);

  return (
    <div className={cn("b-aside__search b-aside-search")}>
      <div className={cn("b-field b-aside-search__field", isFocused ? "b-field_focus" : "")}>
        <div className={cn("b-field__input-box")}>
          <div className={cn("b-text-input")} style={{ height: 36 }}>
            <input
              className={cn("b-text-input__input")}
              placeholder="Search by files"
              type="text"
              value={value}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) => setValue(e.target.value)}
              onKeyUp={(e) => (e.key === "Enter" ? doSearch() : null)}
            />
            <div className={cn("b-text-input__actions")}>
              {value ? (
                <div className={cn("b-text-input__action m-c-light-800")} onClick={clear}>
                  <i className={cn("becon becon--menu--close-big")} />
                </div>
              ) : null}
              <div className={cn("b-text-input__action m-c-light-800")} onClick={doSearch}>
                <i className={cn("becon becon--custom--search")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;

import { usePersistentState } from "../../helpers";

const store = () => {
  const [isAuth, setIsAuth] = usePersistentState<boolean>(false, "user.isAuth");
  const [token, setToken] = usePersistentState<string>("", "user.token");

  return {
    isAuth,
    setIsAuth,
    token,
    setToken,
  };
};

export default store;

import { FC, useState, useRef, cloneElement, ReactElement, LegacyRef } from "react";
import cn from "classnames";
import { onClickOutside } from "../../helpers";

export type ContextMenuListItemIcon = {
  name: string;
  color: string;
};

export type ContextMenuListItem = {
  label: string;
  icon?: ContextMenuListItemIcon;
  onClick: () => void;
};

export type ContextMenuBlock = {
  name?: string;
  list: ContextMenuListItem[];
};

type Props = {
  blocks: ContextMenuBlock[];
  children: ReactElement<any>;
  onContextMenuCallback?: () => void;
  onClickOutsideCallback?: () => void;
};

const ContextMenu: FC<Props> = ({ blocks, children, onContextMenuCallback, onClickOutsideCallback }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fromTop, setFromTop] = useState<boolean>(true);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const menuRef = useRef<HTMLElement>(null);

  const onContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const [x, y] = [e.pageX, e.pageY];

    if (y > window.innerHeight / 2) setFromTop(false);
    setPosition({ x, y });
    setIsOpen(true);

    if (onContextMenuCallback) onContextMenuCallback();
  };

  const childrenWithProps = cloneElement(children, { onContextMenu });

  onClickOutside(menuRef, () => {
    setIsOpen(false);
    setFromTop(true);

    if (onClickOutsideCallback) onClickOutsideCallback();
  });

  return (
    <>
      {childrenWithProps}
      <div
        className={cn("b-context-menu")}
        ref={menuRef as LegacyRef<HTMLDivElement>}
        style={{
          display: isOpen ? "block" : "none",
          position: "fixed",
          zIndex: "1000",
          left: position.x,
          top: position.y,
          transform: fromTop ? "" : "translate3d(0, -100%, 0)",
        }}
      >
        {blocks.map((block) => (
          <div className={cn("b-context-menu__block b-context-menu-block")} key={Math.random()}>
            {block.name ? <div className={cn("b-context-menu-block__name")}>{block.name}</div> : null}
            <div className={cn("b-context-menu-block__list")}>
              {block.list.map((item) => (
                <div
                  className={cn("b-context-menu-block__item")}
                  onClick={() => {
                    item.onClick();
                    setIsOpen(false);
                  }}
                  key={`context-menu-block-item-${item.label}`}
                >
                  {item.icon ? (
                    <div className={cn("b-context-menu-block__item-icon")}>
                      <span className={cn("becon", item.icon.name, `m-c-${item.icon.color}`)} />
                    </div>
                  ) : null}
                  <div className={cn("b-context-menu-block__item-text")}>{item.label}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

ContextMenu.defaultProps = {
  onContextMenuCallback: () => {},
  onClickOutsideCallback: () => {},
};

export default ContextMenu;

import { ChangeEvent, FC, useEffect, useState } from "react";
import cn from "classnames";
import filesSvg from "./img/files.svg";
import { getPreview } from "../../helpers";

type Props = {
  multiple?: boolean;
  onChange: (files: File[]) => void;
};

const FileInput: FC<Props> = ({ multiple, onChange }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  const selectFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const filesArray: File[] = [...files, ...Array.from(e.target.files)];
    const filesArrayNoDuplicates: File[] = filesArray
      .filter((value, index, self) => index === self.findIndex((item) => item.size === value.size && item.name === value.name))
      .filter((item) => item.name.includes("."));

    setFiles(filesArrayNoDuplicates);
    setPreviews(
      filesArrayNoDuplicates.map((file: File) => {
        const extension: string = file.name.split(".").at(-1) as string;

        return getPreview(extension, file);
      }),
    );
  };

  const removeItem = (index: number) => {
    const newFilesValue = [...files];
    const newPreviewsValue = [...previews];

    newFilesValue.splice(index, 1);
    newPreviewsValue.splice(index, 1);

    setFiles(newFilesValue);
    setPreviews(newPreviewsValue);
  };

  useEffect(() => {
    onChange(files);
  }, [files]);

  return (
    <div className={cn("b-file-input")}>
      <input type="file" multiple={multiple} title="" onChange={selectFiles} className={cn("b-file-input__input")} />
      {files.length ? (
        <div className={cn("b-file-input__previews")}>
          {previews.map((item, index) => (
            <div className={cn("b-file-input__preview")} key={Math.random()}>
              <div className={cn("b-file-input__preview-remove")} onClick={() => removeItem(index)}>
                <span className={cn("becon becon--menu--close-big")} />
              </div>
              <img className={cn("b-file-input__preview-image")} src={item} alt="" />
              <div className={cn("b-file-input__preview-name")}>{files[index].name}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className={cn("b-file-input__drag-place")}>
          <img className={cn("b-file-input__drop-icon")} src={filesSvg} alt="Drag place" />
          <div className={cn("b-file-input__drop-title")}>Upload your file</div>
          <div className={cn("b-file-input__drop-text")}>
            To upload, drag files into this area
            <br />
            or simply click on the area
          </div>
          <div className={cn("b-file-input__drop-text b-file-input__drop-text_small")}>
            The size of one file should not exceed 2 GB
          </div>
        </div>
      )}
    </div>
  );
};

FileInput.defaultProps = {
  multiple: false,
};

export default FileInput;

import { useState } from "react";
import { BFile, BFolder } from "../../api/types";

const store = () => {
  /* files */
  const [activeFile, setActiveFile] = useState<BFile | null>(null);
  const [isFileRenameModalShow, setIsFileRenameModalShow] = useState<boolean>(false);
  const [isFileRelationModalShow, setIsFileRelationModalShow] = useState<boolean>(false);
  const [isFileDeleteModalShow, setIsFileDeleteModalShow] = useState<boolean>(false);
  const [isFileAddModalShow, setIsFileAddModalShow] = useState<boolean>(false);

  /* folders */
  const [activeFolder, setActiveFolder] = useState<BFolder | null>(null);
  const [isFolderRenameModalShow, setIsFolderRenameModalShow] = useState<boolean>(false);
  const [isFolderDeleteModalShow, setIsFolderDeleteModalShow] = useState<boolean>(false);
  const [isFolderAddModalShow, setIsFolderAddModalShow] = useState<boolean>(false);

  return {
    activeFile,
    setActiveFile,
    isFileRenameModalShow,
    setIsFileRenameModalShow,
    isFileRelationModalShow,
    setIsFileRelationModalShow,
    isFileDeleteModalShow,
    setIsFileDeleteModalShow,
    isFileAddModalShow,
    setIsFileAddModalShow,

    activeFolder,
    setActiveFolder,
    isFolderRenameModalShow,
    setIsFolderRenameModalShow,
    isFolderDeleteModalShow,
    setIsFolderDeleteModalShow,
    isFolderAddModalShow,
    setIsFolderAddModalShow,
  };
};

export default store;

import { FC } from "react";
import cn from "classnames";
import { saveAs } from "file-saver";
import Store from "../../store";
import { formatDate, formatSizeUnits, getPreview, isImageFile } from "../../helpers";

type Props = {};

const MoreInformation: FC<Props> = () => {
  const store = Store.useContainer();

  const openFileInNewWindow = () => {
    window.open(store.files.selectedFile?.url, "_blank");
  };

  const copyFileUrlToClipboard = async () => {
    await navigator.clipboard.writeText(store.files.selectedFile?.url || "");
  };

  const viewFileRelationships = () => {
    store.modals.setActiveFile(store.files.selectedFile);
    store.modals.setIsFileRelationModalShow(true);
  };

  const downloadFile = () => {
    if (store.files.selectedFile?.url) {
      saveAs(store.files.selectedFile.url, `${store.files.selectedFile.name}.${store.files.selectedFile.extension}`);
    }
  };

  const renameFile = () => {
    store.modals.setActiveFile(store.files.selectedFile);
    store.modals.setIsFileRenameModalShow(true);
  };

  const deleteFile = () => {
    store.modals.setActiveFile(store.files.selectedFile);
    store.modals.setIsFileDeleteModalShow(true);
  };

  if (!store.files.selectedFile)
    return (
      <div className={cn("b-more-information")}>
        <div className={cn("b-more-information__preview")}>
          <img src="https://via.placeholder.com/640x480.png/4545e5?text=Not%20file%20selected" alt="" />
        </div>
        <div className={cn("b-more-information__content")}>
          <div className={cn("b-more-information__title")}>Not file selected</div>
        </div>
      </div>
    );

  return (
    <div className={cn("b-more-information")}>
      <div className={cn("b-more-information__preview")}>
        <img
          src={
            isImageFile(store.files.selectedFile.extension)
              ? store.files.selectedFile.url
              : getPreview(store.files.selectedFile.extension)
          }
          style={{ objectFit: isImageFile(store.files.selectedFile.extension) ? "cover" : "contain" }}
          alt=""
        />
      </div>
      <div className={cn("b-more-information__content")}>
        <div className={cn("b-more-information__title")}>{store.files.selectedFile.name}</div>
        <div className={cn("b-more-information__copy-block")} title={store.files.selectedFile.url}>
          <span>{store.files.selectedFile.url}</span>
          <div className={cn("b-button b-button_xs b-button_light")} onClick={copyFileUrlToClipboard}>
            <div className={cn("b-button__icon")}>
              <span className={cn("becon becon--edit--copy m-c-primary")} />
            </div>
          </div>
        </div>
        <div className={cn("b-more-information__actions")}>
          <div className={cn("b-more-information__action m-c-primary")} onClick={openFileInNewWindow}>
            <span className={cn("becon becon--basic--external-link")} />
          </div>
          <div className={cn("b-more-information__action m-c-primary")} onClick={viewFileRelationships}>
            <span className={cn("becon becon--basic--path")} />
          </div>
          <div className={cn("b-more-information__action m-c-primary")} onClick={downloadFile}>
            <span className={cn("becon becon--basic--download")} />
          </div>
          <div className={cn("b-more-information__action m-c-primary")} onClick={renameFile}>
            <span className={cn("becon becon--custom--edit-3")} />
          </div>
          <div className={cn("b-more-information__action m-c-red")} onClick={deleteFile}>
            <span className={cn("becon becon--basic--trash-empty")} />
          </div>
        </div>
        <div className={cn("b-more-information__actions-hint")}>Select an action</div>
        <div className={cn("b-more-information__meta-block")}>
          <table>
            <thead>
              <tr>
                <td>Format:</td>
                <td>{store.files.selectedFile.extension}</td>
              </tr>
              <tr>
                <td>Size:</td>
                <td>{formatSizeUnits(store.files.selectedFile.size)}</td>
              </tr>
              <tr>
                <td>Creation date:</td>
                <td>{formatDate(store.files.selectedFile.createdAt)}</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MoreInformation;

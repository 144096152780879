import { useEffect, useState } from "react";
import { usePersistentState } from "../../helpers";
import { BFile } from "../../api/types";
import Api from "../../api";

export type BDisplayingType = "list" | "tiles";

const store = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchedFiles, setSearchedFiles] = useState<BFile[]>([]);
  const [displayingType, setDisplayingType] = usePersistentState<BDisplayingType>("list", "common.displayingType");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchByQuery = async () => {
    const data = await Api.files.search(searchQuery);

    await setIsSearch(true);
    await setSearchedFiles(data);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setIsSearch(false);
    setSearchedFiles([]);
  };

  useEffect(() => {
    if (searchQuery) {
      searchByQuery();
    } else {
      clearSearch();
    }
  }, [searchQuery]);

  return {
    isSearch,
    setIsSearch,
    searchedFiles,
    setSearchedFiles,
    searchQuery,
    setSearchQuery,
    clearSearch,
    searchByQuery,
    displayingType,
    setDisplayingType,
    isLoading,
    setIsLoading,
  };
};

export default store;

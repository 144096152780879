const apiUrl: string = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/api/v1` : "";

const Files = () => {
  const localApiUrl: string = `${apiUrl}/files`;

  return {
    getAll: localApiUrl,
    getById: (fileId: number) => `${localApiUrl}/${fileId.toString()}`,
    upload: `${localApiUrl}/upload`,
    rename: (fileId: number) => `${localApiUrl}/${fileId.toString()}/rename`,
    delete: (fileId: number) => `${localApiUrl}/${fileId}/delete`,
    move: (fileId: number, targetFolderId: number | null) =>
      targetFolderId
        ? `${localApiUrl}/${fileId.toString()}/move/${targetFolderId.toString()}`
        : `${localApiUrl}/${fileId.toString()}/move`,
    search: (query: string) => `${localApiUrl}/search?query=${query}`,
  };
};

const Folders = () => {
  const localApiUrl: string = `${apiUrl}/folders`;

  return {
    getAll: localApiUrl,
    getById: (folderId: number) => `${localApiUrl}/${folderId.toString()}`,
    getFolderObjects: (folderId: number | null) =>
      folderId ? `${localApiUrl}/objects/${folderId.toString()}` : `${localApiUrl}/objects`,
    create: `${localApiUrl}/create`,
    rename: (folderId: number) => `${localApiUrl}/${folderId.toString()}/rename`,
    delete: (folderId: number) => `${localApiUrl}/${folderId.toString()}/delete`,
    move: (folderId: number, targetFolderId: number | null) =>
      targetFolderId
        ? `${localApiUrl}/${folderId.toString()}/move/${targetFolderId.toString()}`
        : `${localApiUrl}/${folderId.toString()}/move`,
  };
};

const Storage = () => {
  const localApiUrl: string = `${apiUrl}/storage`;

  return {
    getUsage: `${localApiUrl}/usage`,
  };
};

const User = () => {
  const localApiUrl: string = `${apiUrl}/user`;

  return {
    login: `${localApiUrl}/login`,
  };
};

const Relations = () => {
  const localApiUrl: string = `${apiUrl}/relations`;

  return {
    getFileRelations: (fileId: number) => `${localApiUrl}/file/${fileId}`,
    getFolderRelations: (folderId: number) => `${localApiUrl}/folder/${folderId}`,
  };
};

export const files = Files();
export const folders = Folders();
export const storage = Storage();
export const user = User();
export const relations = Relations();

export default {
  files,
  folders,
  storage,
  user,
  relations,
};

import { DragEvent, FC, MouseEvent, ReactElement, useState } from "react";
import { BFolder } from "../../../../api/types";
import Store from "../../../../store";
import dragPreviewImage from "../../../../assets/img/drag-preview.svg";
import { BDragAndDropPayload, BObjectTypeEnum } from "../../../../helpers/types";
import ListItem from "./ListItem";
import Tile from "./Tile";
import ContextMenu, { ContextMenuBlock } from "../../../ContextMenu";
import { BDisplayingType } from "../../../../store/Common";

type Props = {
  folder: BFolder;
};

const FolderItem: FC<Props> = ({ folder }) => {
  const store = Store.useContainer();
  const [isHover, setIsHover] = useState<boolean>(false);

  const image = new Image();
  image.src = dragPreviewImage;

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDoubleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    store.folders.setSelectedFolder(folder);
  };

  const onDragLeave = () => {
    setIsHover(false);
  };

  const onDragStart = (e: DragEvent<HTMLDivElement>) => {
    const payload: BDragAndDropPayload = {
      type: BObjectTypeEnum.folder,
      id: folder.id,
    };

    e.dataTransfer.setData("text", JSON.stringify(payload));
    e.dataTransfer.setDragImage(image, 20, 20);
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>, destinationFolderId: number) => {
    e.stopPropagation();

    const dataTransfer: BDragAndDropPayload = JSON.parse(e.dataTransfer.getData("text"));

    if (destinationFolderId !== dataTransfer.id) {
      await store.common.setIsLoading(true);

      if (dataTransfer.type === "folder") {
        await store.folders.moveFolder(dataTransfer.id, destinationFolderId);
      }
      if (dataTransfer.type === "file") {
        await store.files.moveFile(dataTransfer.id, destinationFolderId);

        await store.folders.getSelectedFolderObjects();
      }

      await store.common.setIsLoading(false);
    }

    setIsHover(false);
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsHover(true);
  };

  const openFolder = () => {
    store.folders.setSelectedFolder(folder);
  };

  const renameFolder = () => {
    store.modals.setActiveFolder(folder);
    store.modals.setIsFolderRenameModalShow(true);
  };

  const deleteFolder = () => {
    store.modals.setActiveFolder(folder);
    store.modals.setIsFolderDeleteModalShow(true);
  };

  const contextMenu: ContextMenuBlock[] = [
    {
      list: [
        {
          label: "Open folder",
          icon: {
            name: "becon--basic--external-link",
            color: "primary",
          },
          onClick: openFolder,
        },
        {
          label: "Rename",
          icon: {
            name: "becon--custom--edit-3",
            color: "primary",
          },
          onClick: renameFolder,
        },
        {
          label: "Delete",
          icon: {
            name: "becon--basic--trash-empty",
            color: "red",
          },
          onClick: deleteFolder,
        },
      ],
    },
  ];

  const views: Record<BDisplayingType, ReactElement> = {
    tiles: (
      <Tile
        folder={folder}
        onDragLeave={onDragLeave}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
        isHover={isHover}
      />
    ),
    list: (
      <ListItem
        folder={folder}
        onDragLeave={onDragLeave}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onDragOver={onDragOver}
        isHover={isHover}
      />
    ),
  };

  return (
    <ContextMenu
      blocks={contextMenu}
      onContextMenuCallback={() => setIsHover(true)}
      onClickOutsideCallback={() => setIsHover(false)}
    >
      {views[store.common.displayingType]}
    </ContextMenu>
  );
};

export default FolderItem;

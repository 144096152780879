import { FC, useState } from "react";
import Modal from "../index";
import FileInput from "../../FileInput";
import Api from "../../../api";

type Props = {
  id: number | null;
  onCancel: () => void;
  onSuccess: () => void;
};

const AddFileModal: FC<Props> = ({ id, onCancel, onSuccess }) => {
  const [files, setFiles] = useState<File[]>([]);

  const addFile = async (): Promise<void> => {
    await Api.files.upload(files, id || undefined);
  };

  const onCancelWrapper = (): void => {
    onCancel();
  };

  const onSuccessWrapper = async (): Promise<void> => {
    await addFile();

    onSuccess();
  };

  const onChange = (data: File[]) => {
    setFiles(data);
  };

  return (
    <Modal size="md" title="Add file" onCancel={onCancelWrapper} onSuccess={onSuccessWrapper}>
      <FileInput onChange={onChange} multiple />
    </Modal>
  );
};

export default AddFileModal;

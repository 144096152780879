import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Store from "./store";
import "normalize.css/normalize.css";
import "beeds/dist/assets/css/main.css";
import "./assets/scss/main.scss";

document.addEventListener("contextmenu", (e: MouseEvent) => {
  e.preventDefault();
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <Store.Provider>
      <App />
    </Store.Provider>
  </BrowserRouter>,
);

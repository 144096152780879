import { FC } from "react";
import cn from "classnames";
import Modal from "../index";
import Api from "../../../api";
import { BObjectType } from "../../../helpers/types";

type Props = {
  type: BObjectType;
  id: number;
  name: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const DeleteModal: FC<Props> = ({ type, id, name, onCancel, onSuccess }) => {
  const functions: Record<BObjectType, () => void | Promise<void>> = {
    file: async (): Promise<void> => {
      await Api.files.remove(id);
    },
    folder: async (): Promise<void> => {
      await Api.folders.remove(id);
    },
  };

  const onCancelWrapper = (): void => {
    onCancel();
  };

  const onSuccessWrapper = async (): Promise<void> => {
    await functions[type]();

    onSuccess();
  };

  return (
    <Modal size="xs" title={`Delete ${type}`} onCancel={onCancelWrapper} onSuccess={onSuccessWrapper}>
      <div className={cn("m-fs-text-1 m-c-dark m-fw-medium")}>
        Are you sure you want to delete the {type} "{name}"?
      </div>
    </Modal>
  );
};

export default DeleteModal;

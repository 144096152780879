import axios from "axios";
import { BFile, BUserToken, BStorageResp, BFolder, BRelation, BFolderObject } from "./types";
import constants from "./constants";

const Files = {
  getAll: async (): Promise<BFile[]> => {
    try {
      const { status, data } = await axios.get(constants.files.getAll, {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data !== undefined) {
        return data.data as BFile[];
      }

      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return [];
    }
  },
  getById: async (id: number): Promise<BFile | undefined> => {
    try {
      const { status, data } = await axios.get(constants.files.getById(id), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFile;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  upload: async (files: any[], folderId?: number | undefined): Promise<BFile | undefined> => {
    try {
      const { status, data } = await axios.post(
        constants.files.upload,
        {
          files,
          folderId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFile;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, 123);

      return undefined;
    }
  },
  rename: async (fileId: number, newName: string): Promise<BFile | undefined> => {
    try {
      const { status, data } = await axios.put(constants.files.rename(fileId), {
        name: newName,
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFile;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  remove: async (fileId: number): Promise<void> => {
    try {
      const { status, data } = await axios.delete(constants.files.delete(fileId), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300) {
        // eslint-disable-next-line no-console
        console.log(data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  move: async (fileId: number, targetFolderId: number | null): Promise<BFile | undefined> => {
    try {
      const { status, data } = await axios.put(constants.files.move(fileId, targetFolderId), {});

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFile;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  search: async (query: string): Promise<BFile[]> => {
    try {
      const { status, data } = await axios.get(constants.files.search(query), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFile[];
      }

      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return [];
    }
  },
};

const Folders = {
  getAll: async (): Promise<BFolder[]> => {
    try {
      const { status, data } = await axios.get(constants.folders.getAll, {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data !== undefined) {
        return data.data as BFolder[];
      }

      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return [];
    }
  },
  getById: async (id: number): Promise<BFolder | undefined> => {
    try {
      const { status, data } = await axios.get(constants.folders.getById(id), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFolder;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  getFolderObjects: async (folderId: number | null): Promise<BFolderObject | undefined> => {
    try {
      const { status, data } = await axios.get(constants.folders.getFolderObjects(folderId), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFolderObject;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  create: async (name: string, parentId: number | undefined): Promise<BFolder | undefined> => {
    try {
      const { status, data } = await axios.post(constants.folders.create, {
        parentId,
        name,
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFolder;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  rename: async (folderId: number, newName: string): Promise<BFolder | undefined> => {
    try {
      const { status, data } = await axios.put(constants.folders.rename(folderId), {
        name: newName,
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFolder;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
  remove: async (folderId: number): Promise<void> => {
    try {
      const { status, data } = await axios.delete(constants.folders.delete(folderId), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300) {
        // eslint-disable-next-line no-console
        console.log(data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  move: async (folderId: number, targetFolderId: number | null): Promise<BFolder | undefined> => {
    try {
      const { status, data } = await axios.put(constants.folders.move(folderId, targetFolderId), {});

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BFolder;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
};

const Storage = {
  getUsage: async (): Promise<BStorageResp | undefined> => {
    try {
      const { status, data } = await axios.get(constants.storage.getUsage, {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data) {
        return data as BStorageResp;
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  },
};

const User = {
  login: async (email: string, password: string): Promise<BUserToken | null> => {
    try {
      const { status, data } = await axios.post(constants.user.login, {
        password,
        email,
      });

      if (status >= 200 && status < 300 && data.token) {
        return data.token as BUserToken;
      }

      return null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  },
};

const Relations = {
  getFileRelations: async (fileId: number): Promise<BRelation[] | undefined> => {
    try {
      const { status, data } = await axios.get(constants.relations.getFileRelations(fileId), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BRelation[];
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return undefined;
    }
  },
  getFolderRelations: async (folderId: number): Promise<BRelation[] | undefined> => {
    try {
      const { status, data } = await axios.get(constants.relations.getFolderRelations(folderId), {
        headers: {
          Authorization: `Bearer ${window?.localStorage?.getItem("token") || ""}`,
        },
      });

      if (status >= 200 && status < 300 && data.data) {
        return data.data as BRelation[];
      }

      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return undefined;
    }
  },
};

export default {
  files: Files,
  folders: Folders,
  storage: Storage,
  user: User,
  relations: Relations,
};

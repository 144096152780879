import { useState } from "react";
import { BFile } from "../../api/types";
import Api from "../../api";

const store = () => {
  const [files, setFiles] = useState<BFile[]>([]);
  const [selectedFile, setSelectedFile] = useState<BFile | null>(null);

  const moveFile = async (fileId: number, destinationFolderId: number | null) => {
    await Api.files.move(fileId, destinationFolderId);
  };

  return {
    files,
    setFiles,
    selectedFile,
    setSelectedFile,
    moveFile,
  };
};

export default store;

import { FC } from "react";
import cn from "classnames";
import Store from "../../../store";
import Breadcrumbs from "../../Breadcrumbs";
import { BDisplayingType } from "../../../store/Common";

type Props = {};

const HeaderSearch: FC<Props> = () => {
  const store = Store.useContainer();

  const goBack = () => {
    if (store.folders.selectedFolder) {
      const parentFolder = store.folders.selectedFolder.parentId
        ? store.folders.folders[store.folders.idMapping[store.folders.selectedFolder.parentId]]
        : null;

      store.folders.setSelectedFolder(parentFolder);
    } else {
      store.folders.setSelectedFolder(null);
    }
  };

  const refresh = async () => {
    await store.common.setIsLoading(true);

    await store.common.searchByQuery();

    await store.common.setIsLoading(false);
  };

  const changeDisplayingStyle = (type: BDisplayingType) => {
    store.common.setDisplayingType(type);
  };

  return (
    <div className={cn("b-header", "m-bgc-light")}>
      <div className={cn("b-header__inner")}>
        <div className={cn("b-header__actions m-bgc-light-100")} style={{ marginRight: "auto" }}>
          {store.folders.selectedFolder ? (
            <div className={cn("b-header__action")} onClick={goBack}>
              <div className={cn("b-button b-button_sm b-button_light")}>
                <div className={cn("b-button__icon")}>
                  <span className={cn("becon becon--arrow--long-left")} />
                </div>
              </div>
            </div>
          ) : null}
          <div className={cn("b-header__action")} onClick={refresh}>
            <div className={cn("b-button b-button_sm b-button_light")}>
              <div className={cn("b-button__icon")}>
                <span className={cn("becon becon--basic--refresh")} />
              </div>
            </div>
          </div>
          <div className={cn("b-header__breadcrumbs")}>
            <Breadcrumbs />
          </div>
        </div>
        <div className={cn("b-header__actions")}>
          <div className={cn("b-header__action")} onClick={() => changeDisplayingStyle("list")}>
            <div className={cn("b-button b-button_sm b-button_white")}>
              <div className={cn("b-button__icon", store.common.displayingType === "list" ? "m-c-primary" : "")}>
                <span className={cn("becon becon--edit--list-ul")} />
              </div>
            </div>
          </div>
          <div className={cn("b-header__action")} onClick={() => changeDisplayingStyle("tiles")}>
            <div className={cn("b-button b-button_sm b-button_white")}>
              <div className={cn("b-button__icon", store.common.displayingType === "tiles" ? "m-c-primary" : "")}>
                <span className={cn("becon becon--grid--grid-big")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSearch;

import { FC, ReactElement } from "react";
import FolderItem from "./Folder";
import FileItem from "./File";
import { BFolder, BFile } from "../../../api/types";
import { BObjectType } from "../../../helpers/types";

type Props = {
  type: BObjectType;
  data: BFile | BFolder;
};

const Item: FC<Props> = ({ type, data }) => {
  const views: Record<BObjectType, ReactElement> = {
    file: <FileItem file={data as BFile} />,
    folder: <FolderItem folder={data as BFolder} />,
  };

  return views[type];
};

export default Item;

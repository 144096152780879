import { DragEvent, FC, MouseEvent, ReactElement } from "react";
import { saveAs } from "file-saver";
import { BFile } from "../../../../api/types";
import Store from "../../../../store";
import ListItem from "./ListItem";
import Tile from "./Tile";
import { BDragAndDropPayload, BObjectTypeEnum } from "../../../../helpers/types";
import dragPreviewImage from "../../../../assets/img/drag-preview.svg";
import ContextMenu, { ContextMenuBlock } from "../../../ContextMenu";
import { BDisplayingType } from "../../../../store/Common";

type Props = {
  file: BFile;
};

const FileItem: FC<Props> = ({ file }) => {
  const store = Store.useContainer();
  const isSelected: boolean = store.files.selectedFile?.id === file.id;

  const image = new Image();
  image.src = dragPreviewImage;

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    store.files.setSelectedFile(file);
  };

  const onDoubleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(file.url, "_blank");
  };

  const onDragStart = async (e: DragEvent<HTMLDivElement>) => {
    const payload: BDragAndDropPayload = {
      type: BObjectTypeEnum.file,
      id: file.id,
    };

    await e.dataTransfer.setData("text", JSON.stringify(payload));
    await e.dataTransfer.setDragImage(image, 20, 20);
  };

  const openFileInNewWindow = () => {
    window.open(file.url, "_blank");
  };

  const copyFileUrlToClipboard = async () => {
    await navigator.clipboard.writeText(file.url);
  };

  const viewFileRelationships = async () => {
    store.modals.setActiveFile(file);
    store.modals.setIsFileRelationModalShow(true);
  };

  const downloadFile = () => {
    saveAs(file.url, `${file.name}.${file.extension}`);
  };

  const renameFile = () => {
    store.modals.setActiveFile(file);
    store.modals.setIsFileRenameModalShow(true);
  };

  const deleteFile = () => {
    store.modals.setActiveFile(file);
    store.modals.setIsFileDeleteModalShow(true);
  };

  const contextMenu: ContextMenuBlock[] = [
    {
      list: [
        {
          label: "Open in new tab",
          icon: {
            name: "becon--basic--external-link",
            color: "primary",
          },
          onClick: openFileInNewWindow,
        },
        {
          label: "Copy file link",
          icon: {
            name: "becon--basic--link-02",
            color: "primary",
          },
          onClick: copyFileUrlToClipboard,
        },
        {
          label: "View relationships",
          icon: {
            name: "becon--basic--path",
            color: "primary",
          },
          onClick: viewFileRelationships,
        },
        {
          label: "Download",
          icon: {
            name: "becon--basic--download",
            color: "primary",
          },
          onClick: downloadFile,
        },
        {
          label: "Rename",
          icon: {
            name: "becon--custom--edit-3",
            color: "primary",
          },
          onClick: renameFile,
        },
        {
          label: "Delete",
          icon: {
            name: "becon--basic--trash-empty",
            color: "red",
          },
          onClick: deleteFile,
        },
      ],
    },
  ];

  const views: Record<BDisplayingType, ReactElement> = {
    tiles: <Tile file={file} onClick={onClick} onDoubleClick={onDoubleClick} onDragStart={onDragStart} selected={isSelected} />,
    list: (
      <ListItem file={file} onClick={onClick} onDoubleClick={onDoubleClick} onDragStart={onDragStart} selected={isSelected} />
    ),
  };

  return (
    <ContextMenu blocks={contextMenu} onContextMenuCallback={() => store.files.setSelectedFile(file)}>
      {views[store.common.displayingType]}
    </ContextMenu>
  );
};

export default FileItem;

import { FC } from "react";
import cn from "classnames";
import { FileTileProps } from "../types";
import { formatDate, formatSizeUnits, isImageFile } from "../../../../../helpers";

const Tile: FC<FileTileProps> = ({ file, onClick, onDoubleClick, onDragStart, selected, onContextMenu }) => {
  const isImage = isImageFile(file.extension);
  const getIcon: string = isImage ? "becon--file--file-image" : "becon--file--file-blank_fill";

  return (
    <div
      draggable="true"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
      onDragStart={onDragStart}
      className={cn("b-ff-tile file", selected ? "selected" : "")}
    >
      <div className={cn("b-ff-tile__inner")}>
        <div className={cn("b-ff-tile__header")}>
          {isImage ? (
            <div className={cn("b-ff-tile__preview")} style={{ backgroundImage: `url(${file.url})` }} />
          ) : (
            <div className={cn("b-ff-tile__icon m-c-primary m-fs-text-3")}>
              <span className={cn("becon", getIcon)} />
            </div>
          )}
          <div className={cn("b-ff-tile__extension", isImage ? "image" : "")}>{file.extension}</div>
        </div>
        <div className={cn("b-ff-tile__main")}>
          <div className={cn("b-ff-tile__meta")}>
            <div>
              Last change: <strong>{formatDate(file.updatedAt)}</strong>
            </div>
            <div>{formatSizeUnits(file.size)}</div>
          </div>
          <div className={cn("b-ff-tile__title")}>{file.name}</div>
        </div>
        <div className={cn("b-ff-tile__footer")}>
          <div className={cn("b-ff-tile__drag-place")}>
            <span className="becon becon--custom--more-vertical" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tile;

import { FC } from "react";
import RenameModal from "../Rename";
import { BObjectTypeEnum } from "../../../helpers/types";
import RelationModal from "../Relation";
import DeleteModal from "../Delete";
import Store from "../../../store";
import Api from "../../../api";
import AddFolderModal from "../AddFolder";
import AddFileModal from "../AddFile";

type Props = {};

const CollectionOfModals: FC<Props> = () => {
  const store = Store.useContainer();

  const fileRenameModalOnSuccess = async () => {
    await store.common.setIsLoading(true);
    await store.folders.getSelectedFolderObjects();
    await store.modals.setIsFileRenameModalShow(false);
    await store.modals.setActiveFile(null);
    await store.common.setIsLoading(false);
  };

  const fileRelationModalOnSuccess = async () => {
    await store.modals.setIsFileRelationModalShow(false);
    await store.modals.setActiveFile(null);
  };

  const fileDeleteModalOnSuccess = async () => {
    await store.common.setIsLoading(true);
    await store.folders.getSelectedFolderObjects();
    await store.modals.setIsFileDeleteModalShow(false);
    await store.files.setSelectedFile(null);
    await store.modals.setActiveFile(null);
    await store.common.setIsLoading(false);
  };

  const fileAddModalOnSuccess = async () => {
    await store.common.setIsLoading(true);
    await store.folders.getSelectedFolderObjects();
    await store.modals.setIsFileAddModalShow(false);
    await store.modals.setActiveFolder(null);
    await store.common.setIsLoading(false);
  };

  const folderAddModalOnSuccess = async () => {
    await store.common.setIsLoading(true);
    await store.folders.getSelectedFolderObjects();
    await store.folders.setFolders(await Api.folders.getAll());
    await store.modals.setIsFolderAddModalShow(false);
    await store.modals.setActiveFolder(null);
    await store.common.setIsLoading(false);
  };

  const folderRenameModalOnSuccess = async () => {
    await store.common.setIsLoading(true);
    await store.folders.getSelectedFolderObjects();
    await store.folders.setFolders(await Api.folders.getAll());
    await store.modals.setIsFolderRenameModalShow(false);
    await store.modals.setActiveFolder(null);
    await store.common.setIsLoading(false);
  };

  const folderDeleteModalOnSuccess = async () => {
    await store.common.setIsLoading(true);
    await store.folders.getSelectedFolderObjects();
    await store.folders.setFolders(await Api.folders.getAll());
    await store.modals.setIsFolderDeleteModalShow(false);
    await store.modals.setActiveFolder(null);
    await store.common.setIsLoading(false);
  };

  return (
    <>
      {/* files */}
      {store.modals.isFileRenameModalShow && store.modals.activeFile ? (
        <RenameModal
          type={BObjectTypeEnum.file}
          id={store.modals.activeFile.id}
          name={store.modals.activeFile.name}
          onCancel={() => store.modals.setIsFileRenameModalShow(false)}
          onSuccess={fileRenameModalOnSuccess}
        />
      ) : null}
      {store.modals.isFileRelationModalShow && store.modals.activeFile ? (
        <RelationModal
          type={BObjectTypeEnum.file}
          id={store.modals.activeFile.id}
          onCancel={() => store.modals.setIsFileRelationModalShow(false)}
          onSuccess={fileRelationModalOnSuccess}
        />
      ) : null}
      {store.modals.isFileDeleteModalShow && store.modals.activeFile ? (
        <DeleteModal
          type={BObjectTypeEnum.file}
          id={store.modals.activeFile.id}
          name={store.modals.activeFile.name}
          onCancel={() => store.modals.setIsFileDeleteModalShow(false)}
          onSuccess={fileDeleteModalOnSuccess}
        />
      ) : null}
      {store.modals.isFileAddModalShow ? (
        <AddFileModal
          id={store.modals.activeFolder?.id || null}
          onCancel={() => store.modals.setIsFileAddModalShow(false)}
          onSuccess={fileAddModalOnSuccess}
        />
      ) : null}

      {/* folders */}
      {store.modals.isFolderRenameModalShow && store.modals.activeFolder ? (
        <RenameModal
          type={BObjectTypeEnum.folder}
          id={store.modals.activeFolder.id}
          name={store.modals.activeFolder.name}
          onCancel={() => store.modals.setIsFolderRenameModalShow(false)}
          onSuccess={folderRenameModalOnSuccess}
        />
      ) : null}
      {store.modals.isFolderAddModalShow ? (
        <AddFolderModal
          id={store.modals.activeFolder?.id || null}
          onCancel={() => store.modals.setIsFolderAddModalShow(false)}
          onSuccess={folderAddModalOnSuccess}
        />
      ) : null}
      {store.modals.isFolderDeleteModalShow && store.modals.activeFolder ? (
        <DeleteModal
          type={BObjectTypeEnum.folder}
          id={store.modals.activeFolder.id}
          name={store.modals.activeFolder.name}
          onCancel={() => store.modals.setIsFolderDeleteModalShow(false)}
          onSuccess={folderDeleteModalOnSuccess}
        />
      ) : null}
    </>
  );
};

export default CollectionOfModals;

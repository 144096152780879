import { FC } from "react";
import cn from "classnames";

type Props = {};

const Loader: FC<Props> = () => {
  return (
    <div className={cn("b-loader")}>
      <div className={cn("b-loader__anim")}>
        <svg width="80" height="80" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" aria-label="audio-loading">
          <defs>
            <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
              <stop stopColor="#4545e5" stopOpacity="0" offset="0%" />
              <stop stopColor="#4545e5" stopOpacity=".631" offset="63.146%" />
              <stop stopColor="#4545e5" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)">
              <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="#4545e5" strokeWidth="2">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </path>
              <circle fill="#fff" cx="36" cy="18" r="1">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </g>
        </svg>
      </div>
      <div className={cn("b-loader__text")}>Loading...</div>
    </div>
  );
};

export default Loader;

import { FC, useState, MouseEvent, DragEvent } from "react";
import cn from "classnames";
import Store from "../../../../store";
import dragPreviewImage from "../../../../assets/img/drag-preview.svg";
import { BFolder } from "../../../../api/types";
import { BDragAndDropPayload, BObjectTypeEnum } from "../../../../helpers/types";
import Api from "../../../../api";

export type ItemProps = {
  name: string;
  id: number | null;
  children: BFolder[];
  defaultOpen?: boolean;
};

const Item: FC<ItemProps> = ({ name, id, children = [], defaultOpen = false }) => {
  const store = Store.useContainer();
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const [isHover, setIsHover] = useState<boolean>(false);

  const image = new Image();
  image.src = dragPreviewImage;

  const onDragLeave = () => {
    setIsHover(false);
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const dataTransfer: BDragAndDropPayload = JSON.parse(e.dataTransfer.getData("text"));

    if (id !== dataTransfer.id) {
      await store.common.setIsLoading(true);

      if (dataTransfer.type === "folder") {
        await store.folders.moveFolder(dataTransfer.id, id);

        await store.folders.setFolders(await Api.folders.getAll());
      }
      if (dataTransfer.type === "file") {
        await store.files.moveFile(dataTransfer.id, id);

        await store.folders.getSelectedFolderObjects();
      }

      await store.common.setIsLoading(false);
    }

    setIsHover(false);
  };

  const onDragOver = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    await setIsHover(true);
  };

  const onDragStart = async (e: DragEvent<HTMLDivElement>) => {
    if (!id) return;

    const payload: BDragAndDropPayload = {
      type: BObjectTypeEnum.folder,
      id,
    };

    await e.dataTransfer.setData("text", JSON.stringify(payload));
    await e.dataTransfer.setDragImage(image, 20, 20);
  };

  const selectFolder = () => {
    const folder = id ? store.folders.folders[store.folders.idMapping[id]] : null;

    store.folders.setSelectedFolder(folder);
    store.common.clearSearch();
  };

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (id !== null) {
      setIsOpen(!isOpen);
    }

    selectFolder();
  };

  const addFolder = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!id) return;

    const folder = store.folders.folders[store.folders.idMapping[id]];
    store.modals.setActiveFolder(folder);
    store.modals.setIsFolderAddModalShow(true);
  };

  const renameFolder = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!id) return;

    const folder = store.folders.folders[store.folders.idMapping[id]];

    store.modals.setActiveFolder(folder);
    store.modals.setIsFolderRenameModalShow(true);
  };

  const deleteFolder = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!id) return;

    const folder = store.folders.folders[store.folders.idMapping[id]];

    store.modals.setActiveFolder(folder);
    store.modals.setIsFolderDeleteModalShow(true);
  };

  const getTitleColor = (): string => {
    if ((store.folders.selectedFolder?.id || null) === id) return "m-c-primary";

    if (store.folders.selectedFolder?.id !== id && !isOpen) return "m-c-dark-100";

    return "m-c-dark";
  };

  return (
    <div
      className={cn("b-aside-tree__item", isOpen ? "open" : "", isHover ? "m-bgc-light-100" : "")}
      draggable="true"
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
      onClick={onClick}
    >
      <div className="b-aside-tree__item-inner">
        <div
          className={cn(
            "b-aside-tree__item-arrow",
            isOpen && children.length ? "m-c-primary" : "m-c-light-800",
            !children.length ? "no-children" : "",
          )}
        >
          <span className={cn("becon", isOpen ? "becon--arrow--caret-down" : "becon--arrow--caret-right")} />
        </div>
        <div className={cn("b-aside-tree__item-drag-place m-c-light-800")}>
          <span className={cn("becon becon--custom--more-vertical")} />
        </div>
        <div className={cn("b-aside-tree__item-title m-fs-text-2 m-fw-medium", getTitleColor())}>{name}</div>
        {id ? (
          <div className={cn("b-aside-tree__item-actions")}>
            <div className={cn("b-aside-tree__item-action m-fs-text-2")} onClick={addFolder}>
              <span className={cn("becon becon--file--folder-plus")} />
            </div>
            <div className={cn("b-aside-tree__item-action m-fs-text-2")} onClick={renameFolder}>
              <span className={cn("becon becon--edit--edit")} />
            </div>
            <div className={cn("b-aside-tree__item-action m-fs-text-2")} onClick={deleteFolder}>
              <span className={cn("becon becon--basic--trash-empty")} />
            </div>
          </div>
        ) : null}
      </div>
      {children?.length
        ? children.map((item) => (
            <div className={cn("b-aside-tree__list")} key={`${item.name}-${item.id}`}>
              {/* eslint-disable-next-line react/no-children-prop */}
              <Item name={item.name} id={item.id} children={item.children || []} />
            </div>
          ))
        : null}
    </div>
  );
};

Item.defaultProps = {
  defaultOpen: false,
};

export default Item;

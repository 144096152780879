import { FC, useEffect, useState } from "react";
import cn from "classnames";
import Store from "../../../store";
import { formatSizeUnits } from "../../../helpers";

type Props = {};

const Information: FC<Props> = () => {
  const store = Store.useContainer();
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    if (!store.storage.used || !store.storage.available) return;

    const p = (store.storage.used / store.storage.available) * 100;

    setPercent(Math.round(p * 100) / 100);
  }, [store.storage.used, store.storage.available]);

  useEffect(() => {
    store.storage.getData();
  }, []);

  return (
    <div className={cn("b-aside__information b-aside-information")}>
      <div className="b-aside-information__usage m-c-dark m-fw-medium m-fs-title-1">
        {formatSizeUnits(store.storage.used)} / {formatSizeUnits(store.storage.available)}
      </div>
      <div className="b-aside-information__description m-c-dark-400 m-fs-text-1">
        <span className={cn("m-c-dark m-fw-medium")}>{percent}%</span> of storage used
      </div>
      <div className="b-aside-information__progress">
        <div className={cn("b-aside-information__progress-value")} style={{ width: `${percent}%` }} />
      </div>
      <div className="b-button b-button_sm b-button_light b-aside-information__button">
        <div className={cn("b-button__icon m-c-primary")}>
          <span className={cn("becon becon--arrow--chevron-duo_up")} />
        </div>
        <div className={cn("b-button__value")}>Switch to another tariff</div>
      </div>
    </div>
  );
};

export default Information;

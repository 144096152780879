import { createContainer } from "unstated-next";
import files from "./Files";
import folders from "./Folders";
import storage from "./Storage";
import user from "./User";
import common from "./Common";
import modals from "./Modals";

const useContainer = () => ({
  files: files(),
  folders: folders(),
  storage: storage(),
  user: user(),
  common: common(),
  modals: modals(),
});

export default createContainer(useContainer);

import { FC } from "react";
import cn from "classnames";
import Store from "../../store";

type Props = {};

const Breadcrumbs: FC<Props> = () => {
  const store = Store.useContainer();

  return (
    <div className={cn("b-breadcrumbs")}>
      <div className={cn("b-breadcrumbs__item m-fs-text-2 m-fw-medium")} onClick={() => store.folders.setSelectedFolder(null)}>
        Storage
      </div>
      {store.common.isSearch ? (
        <div className={cn("b-breadcrumbs__item m-fs-text-2 m-fw-medium")}>
          Determination results «{store.common.searchQuery}»
        </div>
      ) : (
        <>
          {store.folders.pathToCurrentFolder.map((item) => (
            <div
              key={`breadcrumbs-${item.id}`}
              className={cn("b-breadcrumbs__item m-fs-text-2 m-fw-medium")}
              onClick={() => store.folders.setSelectedFolder(item)}
            >
              {item.name}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Breadcrumbs;

import { BFolder, BFile } from "../../api/types";

type Data = {
  folders: BFolder[];
  files: BFile[];
};

const getRandomNumber = (): number => Math.floor(Math.random() * 100) + 1;

const getPreloadFolders = (length: number = 3): BFolder[] =>
  Array.from({ length }, (_, index) => ({
    id: getRandomNumber(),
    userId: 0,
    parentId: null,
    path: "string",
    name: `Example folder ${index}`,
    elementCount: 0,
    isTrash: false,
    createdAt: "2023-10-06T10:57:03.000000Z",
    updatedAt: "2023-10-06T10:57:03.000000Z",
    fileCount: 0,
    size: 0,
  }));

const getPreloadFiles = (length: number = 5): BFile[] =>
  Array.from({ length }, (_, index) => ({
    createdAt: "2023-10-06T10:57:03.000000Z",
    extension: "doc",
    folderId: getRandomNumber(),
    id: getRandomNumber(),
    isTrash: 0,
    name: `Example file ${index}`,
    size: 0,
    updatedAt: "2023-10-06T10:57:03.000000Z",
    url: "string",
    userId: getRandomNumber(),
  }));

const data: Data = {
  folders: getPreloadFolders(),
  files: getPreloadFiles(),
};

export default data;

import { FC, useState, useRef, useEffect } from "react";
import cn from "classnames";
import Modal from "../index";
import Api from "../../../api";

type Props = {
  id: number | null;
  onCancel: () => void;
  onSuccess: () => void;
};

const AddFolderModal: FC<Props> = ({ id, onCancel, onSuccess }) => {
  const [name, setName] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const addFolder = async (): Promise<void> => {
    await Api.folders.create(name, id || undefined);
  };

  const onCancelWrapper = (): void => {
    onCancel();
  };

  const onSuccessWrapper = async (): Promise<void> => {
    await addFolder();

    onSuccess();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Modal size="xs" title="Add folder" onCancel={onCancelWrapper} onSuccess={onSuccessWrapper}>
      <div className={cn("b-field b-aside-search__field")}>
        <div className={cn("b-field__input-box")}>
          <div className={cn("b-text-input")}>
            <input
              ref={inputRef}
              className={cn("b-text-input__input")}
              placeholder="Enter a new name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyUp={(e) => (e.key === "Enter" ? onSuccessWrapper() : null)}
            />
            <div className={cn("b-text-input__actions")}>
              <div className={cn("b-text-input__action m-c-light-800")} onClick={() => setName("")}>
                <i className={cn("becon becon--menu--close-big")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddFolderModal;
